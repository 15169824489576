<script setup lang="ts">
const userStore = useUserStore();
</script>

<template>
  <NuxtLayout>
    <template #header-title>
      ️Bonjour {{ userStore.currentUser?.firstname }} ️
    </template>

    <template #header-cta>
      <p>pas de nouvelle notification ☀️️</p>
    </template>

    <div class="home-nutrician">
      <slot></slot>
    </div>
  </NuxtLayout>
</template>

<style scoped></style>
